import { useMutation, useQueryClient } from "@tanstack/react-query";
import SignatureComponent from "components/inputs/Signature";
import PreviewButton from "containers/common/Previewers/PreviewButton";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import plans from "utils/api/plans";
import users from "utils/api/users";
import FileType from "utils/enums/FileType";
import PlanStatus from "utils/enums/PlanStatus";

type Props = {
  plan: any;
};

const PlanCinetSignatureActionsForm = (props: Props) => {
  const { plan } = props;
  const status = PlanStatus.PENDING_FI;
  const [success, setSuccess] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [show, setShow] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const { t } = useTranslation(["plans", "common"]);
  const client = useQueryClient();
  const navigate = useNavigate();
  const signMutation = useMutation(() => plans.signCinet(plan.id), {
    onSuccess: () => {
      client.invalidateQueries(["plan"]);
      setSuccess(true);
    },
  });

  const updateStatusMutation = useMutation(
    () => plans.updateStatus(plan.id, { status }),
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        setShow(false);
      },
    }
  );

  const uploadMutation = useMutation(users.upload, {
    onSuccess: () => signMutation.mutate(),
  });

  async function generateCinet(values: any) {
    uploadMutation.mutate([
      { data: { type: "signature" }, file: values.signature },
    ]);
  }

  async function submit() {
    updateStatusMutation.mutate();
  }

  const shouldSign = PlanStatus.PENDING_CINET == plan.status;
  const currentForm = plan.files?.find((i: any) => i.type == FileType.CINET);
  if (!shouldSign) return <></>;

  return (
    <>
      <Button
        variant="primary"
        onClick={() => setShow(true)}
        size="lg"
        className="w-100 my-3"
        type="button"
      >
        {t("sign_form_here")}
      </Button>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={() => setShow(false)}
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <Modal.Title className="fs-6">
            {t("sign_form_here")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {success && (
            <>
              <h4 className="text-center">{t("your_cinet_ready")}</h4>
              {currentForm?.id && (
                <PreviewButton id={currentForm.id} label={t("plans:view")} />
              )}
              <Button
                variant="light"
                size="lg"
                onClick={() => setSuccess(false)}
                className="w-100 mt-3"
                disabled={updateStatusMutation.isLoading}
              >
                {t("common:re-sign")}
              </Button>
              <Button
                size="lg"
                onClick={submit}
                className="w-100 mt-3"
                disabled={updateStatusMutation.isLoading}
              >
                {t("accept_cinet")}
              </Button>
            </>
          )}
          {!success && shouldSign && (
            <Formik
              onSubmit={generateCinet}
              initialValues={{
                status,
                signature: new Blob(),
              }}
            >
              <Form>
                {uploadMutation.error && (
                  // @ts-ignore
                  <Alert color="danger">{uploadMutation.error.message}</Alert>
                )}
                <h4 className="fw-bold">{t("cinet_consent_title")}</h4>
                <h6 className="">
                  {t("cinet_name_confirm")}
                </h6>
                <p className="text-info fw-bold">
                  {plan.user.name}
                </p>
                {!agreed && (
                  <>
                    <p className="fw-bold my-2">
                      {t("cinet_agreeing_to_the_following")}
                    </p>
                    {showConsent ?
                      <p className="fs-7">
                        {t("cinet_consent_desc", { fiName: plan.fi.name })}
                      </p> :
                      <p className="fs-7">
                        {t("cinet_consent_desc", { fiName: plan.fi.name }).slice(0, 100)}
                        ...
                      </p>}
                    <Button
                      variant="link"
                      className="p-0"
                      onClick={() => setShowConsent(!showConsent)}
                    >
                      {showConsent ? t("common:read_less") : t("common:read_more")}
                    </Button>
                  </>
                )}
                {!agreed && (
                  <Row className="mt-3">
                    <Col md="6">
                      <Button
                        className="w-100 mt-2"
                        size="lg"
                        variant="primary"
                        type="button"
                        onClick={() => {
                          setAgreed(true);
                          setShowConsent(false);
                        }}
                      >
                        {t("common:agree")}
                      </Button>
                    </Col>
                    <Col md="6">
                      <Button
                        className="w-100 mt-2"
                        size="lg"
                        variant="danger"
                        type="button"
                        onClick={() => navigate("/users/profile")}
                      >
                        {t("update_my_name")}
                      </Button>
                    </Col>
                  </Row>
                )}

                {agreed && (
                  <>
                    <SignatureComponent name="signature" />
                    <Button
                      className="w-100 mt-4"
                      size="lg"
                      color="primary"
                      type="submit"
                      disabled={
                        uploadMutation.isLoading || signMutation.isLoading
                      }
                    >
                      {t("generate_cinet")}
                    </Button>
                  </>
                )}
              </Form>
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PlanCinetSignatureActionsForm;
