import { useMutation, useQuery } from "@tanstack/react-query";
import StudentForm from "components/forms/Student";
import Radio from "components/inputs/Radio";
import Icon from "components/widgets/Icon";
import { Formik, FormikHelpers } from "formik";
import { DateTime } from "luxon";
import { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import plans from "utils/api/plans";
import { settings } from "utils/api/settings";
import students from "utils/api/students";
import { gradeLabel } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";
import * as Yup from "yup";
import ReferralCode from "../components/ReferralCode";
import cx from 'classnames';
import { usePaginate } from "utils/hooks/paginate";

type Props = {
  nextStep: () => void;
};

const PlanSelectStudents = (props: Props) => {
  const { nextStep } = props;
  const [newStudentModal, setNewStudentModal] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(["plans"]);
  const { data: user } = useUser();
  const paginate = usePaginate({
    key: "students",
    api: students.list,
    filter: {},
    options: {
      placeholderData: []
    }
  }); 
  const { data: plan } = useQuery(["plans", id], () => plans.view(id), {
    initialData: {},
    enabled: Boolean(id),
  });
  const { data: applyYear } = useQuery(["apply_year",], () => settings.viewByKey("apply_year"), {
    select: (data: any) => {
      return data.value;
    },
  });

  const createPlan = useMutation((values) => plans.store(values));
  const updatePlan = useMutation((values) => plans.update(id, values));

  async function submit(values: any, formikBag: FormikHelpers<any>) {
    // if has id, update
    try {
      const payload = {
        ...values,
        school_id: values.students[0].school.id,
        registered: true,
      };
      if (!id) {
        const data = await createPlan.mutateAsync(payload);
        TagManager.dataLayer({
          dataLayer: {
            event: "submit_incomplete_plan",
          },
        });
        navigate(`/plans/${data.id}/edit`);
      } else {
        await updatePlan.mutateAsync(payload);
      }
      nextStep();
    } catch (e) {
      console.log(e);
    }
  }

  function lockStudent(students: any, student: any): boolean {
    let currentSchool = null;
    if (students.length > 0) {
      currentSchool = students[0].school.id;
      if (student.school.id == currentSchool) return false;
    } else {
      return false;
    }
    return true;
  }

  return (
    <>
      <Modal
        size="lg"
        show={newStudentModal}
        onHide={() => setNewStudentModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("plans:add_student")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StudentForm close={() => setNewStudentModal(false)} />
        </Modal.Body>
      </Modal>
      <Formik
        enableReinitialize
        initialValues={{
          students: [],
          ...plan,
          referral_code: plan?.incomingReferrals?.[0]?.code ?? '',
        }}
        validationSchema={Yup.object().shape({
          students: Yup.array().min(1, t("select_at_least_one_student")),
          current_year: Yup.string().required(),
        })}
        onSubmit={submit}
      >
        {(props) => (
          <>
            <Col lg={8}>
              <Row>
                <Col lg={12}>
                  <h5 className="text-dark fw-bold">
                    {t("select_students_pay_fees")}
                  </h5>
                </Col>
                {paginate?.data?.data?.map((student: any) => (
                  <Col lg={12} key={student.id} className="mb-3">
                    <Form.Check
                      id={student.id}
                      className="student-selector position-relative mx-0 p-0"
                      type="checkbox"
                    >
                      <Form.Check.Input
                        type="checkbox"
                        checked={props.values.students.some(
                          (s: any) => s.id == student.id
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            props.setFieldValue("students", [
                              ...props.values.students,
                              student,
                            ]);
                          } else {
                            props.setFieldValue("students", [
                              ...props.values.students.filter(
                                (s: any) => s.id != student.id
                              ),
                            ]);
                          }
                        }}
                        disabled={lockStudent(props.values.students, student)}
                      />
                      <Form.Check.Label className="w-100 px-5 py-3 bg-light-blue border border-info rounded-1">
                        <p className="fw-bold mb-1">{student.name}</p>
                        <p className="fs-7">
                          {student.school?.name} - {gradeLabel(student.grade)}
                        </p>
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                ))}
                <Col lg={12}>
                  {paginate?.data?.data?.length == 0 && (
                    <Alert variant="danger">
                      {t("click_on_add_new_student")}
                    </Alert>
                  )}
                  <Button
                    onClick={() => setNewStudentModal(true)}
                    variant="white"
                    className={cx("border rounded-2 py-2 px-3",
                      { 'w-100 py-3': paginate?.data?.data?.length == 0 }
                    )}
                    size="sm"
                  >
                    <Icon name="boxedPlus" /> {t("add_student")}
                  </Button>
                </Col>
                <Col lg={12} className="mt-3">
                  <p className="fw-semibold">{t("this_year_or_next")}</p>
                </Col>
                <Col lg={6} className="my-2">
                  <Radio
                    name="current_year"
                    value={applyYear?.first || DateTime.now().year}
                    // previous_year
                    label={applyYear?.first || DateTime.now().year}
                  />
                </Col>
                {/* only if second exists */}
                {applyYear?.second && (
                  <Col lg={6} className="my-2">
                    <Radio
                      name="current_year"
                      value={applyYear?.second || DateTime.now().year + 1}
                      label={applyYear?.second || DateTime.now().year + 1}
                    />
                  </Col>
                )}
                <Col lg={{ span: 6, offset: 6 }} className="mb-3">
                  <ReferralCode />
                </Col>
              </Row>
            </Col>

            <Col lg={12} className="d-flex justify-content-between">
              <Button
                onClick={() => navigate("/dashboard")}
                variant="light"
                size="lg"
              >
                {user?.language == "ar" ? (
                  <i className="bi bi-arrow-right ms-3"></i>
                ) : (
                  <i className="bi bi-arrow-left me-3"></i>
                )}
                {t("common:back")}
              </Button>
              <Button onClick={props.submitForm} size="lg">
                {t("common:next")}
                {user?.language == "ar" ? (
                  <i className="bi bi-arrow-left me-3"></i>
                ) : (
                  <i className="bi bi-arrow-right ms-3"></i>
                )}
              </Button>
            </Col>
          </>
        )}
      </Formik>
    </>
  );
};

export default PlanSelectStudents;
