import PlanStatus from "utils/enums/PlanStatus";
import Role from "utils/enums/Role";

const baseStatus = {
  [PlanStatus.INCOMPLETE]: PlanStatus.INCOMPLETE,
  [PlanStatus.PENDING_DOCUMENTS]: PlanStatus.PENDING_DOCUMENTS,
  [PlanStatus.ARCHIVED]: PlanStatus.ARCHIVED,
  [PlanStatus.USER_WITHDRAWN]: PlanStatus.USER_WITHDRAWN,
  [PlanStatus.PENDING_RUBA]: PlanStatus.PENDING_RUBA,
  [PlanStatus.MISSING_DOCUMENTS_RUBA]: PlanStatus.MISSING_DOCUMENTS_RUBA,
  [PlanStatus.REJECTED_RUBA]: PlanStatus.REJECTED_RUBA,
  [PlanStatus.PENDING_QUOTATION]: PlanStatus.PENDING_QUOTATION,
  [PlanStatus.PENDING_FI]: PlanStatus.PENDING_FI,
  [PlanStatus.PENDING_CINET]: PlanStatus.PENDING_CINET,
  [PlanStatus.MISSING_DOCUMENTS_FI]: PlanStatus.MISSING_DOCUMENTS_FI,
  [PlanStatus.REJECTED_FI]: PlanStatus.REJECTED_FI,
  [PlanStatus.REJECTED_SCHOOL]: PlanStatus.REJECTED_SCHOOL,
  [PlanStatus.PENDING_SIGNATURE]: PlanStatus.PENDING_SIGNATURE,
  [PlanStatus.SEAT_WITHDRAWN]: PlanStatus.SEAT_WITHDRAWN,
  [PlanStatus.PENDING_RECEIPT]: PlanStatus.PENDING_RECEIPT,
  [PlanStatus.PENDING_INVOICE]: PlanStatus.PENDING_INVOICE,
  [PlanStatus.PENDING_PAYMENT]: PlanStatus.PENDING_PAYMENT,
  [PlanStatus.PENDING_RUBA_TID]: PlanStatus.PENDING_RUBA_TID,
  [PlanStatus.PENDING_PAYMENT_SCHOOL]: PlanStatus.PENDING_PAYMENT_SCHOOL,
  [PlanStatus.PENDING_RUBA_SCHOOL_TID]: PlanStatus.PENDING_RUBA_SCHOOL_TID,
  [PlanStatus.ACTIVE]: PlanStatus.ACTIVE,
  [PlanStatus.COMPLETE]: PlanStatus.COMPLETE,
};

export default function StatusPerUser(params) {
  if (params.role == Role.USER) {
    const modifiedStatus = {
      ...baseStatus,
      [PlanStatus.ARCHIVED]: PlanStatus.INCOMPLETE,
      [PlanStatus.MISSING_DOCUMENTS_RUBA]: "missing_documents",
      [PlanStatus.MISSING_DOCUMENTS_FI]: "missing_documents",
      [PlanStatus.REJECTED_RUBA]: "not_eligible",
      [PlanStatus.REJECTED_FI]: "not_eligible",
      [PlanStatus.PENDING_RECEIPT]: PlanStatus.ACTIVE,
      [PlanStatus.PENDING_QUOTATION]: "pending_school",
      [PlanStatus.PENDING_RUBA_SCHOOL_TID]: PlanStatus.ACTIVE,
      [PlanStatus.PENDING_PAYMENT_SCHOOL]: PlanStatus.ACTIVE,
      [PlanStatus.PENDING_RUBA_TID]: PlanStatus.ACTIVE,
      [PlanStatus.PENDING_PAYMENT]: PlanStatus.ACTIVE,
    }
    if (params.intermediated) {
      modifiedStatus[PlanStatus.PENDING_RUBA_TID] = PlanStatus.PENDING_FI
    }
    return modifiedStatus
  }

  if (params.role == Role.SCHOOL) {
    const modifiedStatus = {
      ...baseStatus,
      [PlanStatus.ARCHIVED]: PlanStatus.INCOMPLETE,
      [PlanStatus.PENDING_DOCUMENTS]: PlanStatus.INCOMPLETE,
      [PlanStatus.MISSING_DOCUMENTS_RUBA]: "User: Missing Documents",
      [PlanStatus.MISSING_DOCUMENTS_FI]: "User: Missing Documents",
      [PlanStatus.PENDING_SIGNATURE]: PlanStatus.PENDING_SERVICE_FEE,
      [PlanStatus.PENDING_CINET]: PlanStatus.PENDING_FI,
      [PlanStatus.PENDING_RUBA_TID]: PlanStatus.COMPLETE,
    }
    if (params.intermediated) {
      modifiedStatus[PlanStatus.PENDING_RUBA_TID] = PlanStatus.PENDING_FI
    }

    return modifiedStatus
  }

  if (params.role == Role.FI) {
    const modifiedStatus = {
      ...baseStatus,
      [PlanStatus.ARCHIVED]: PlanStatus.INCOMPLETE,
      [PlanStatus.PENDING_RUBA_SCHOOL_TID]: PlanStatus.ACTIVE,
      [PlanStatus.PENDING_SERVICE_FEE]: PlanStatus.PENDING_SIGNATURE
    }
    return modifiedStatus
  }

  return baseStatus
}

