import { useQuery } from "@tanstack/react-query";
import MediumLineCard from "components/widgets/Dashboard/MediumLineCard";
import MediumPieCard from "components/widgets/Dashboard/MediumPieCard";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import dashboard from "utils/api/dashboard";

const FIStatsWidget = () => {
  const { data, isFetching } = useQuery(["dashboard"], dashboard.fiStats)

  const { t } = useTranslation(["common", "fis"]);

  return (
    <>
      <Col lg="12" className="my-3">
        <p className="fw-bold">{t("stats")}</p>
      </Col>
      <Col lg={4} className="mb-3">
        <MediumLineCard
          loading={isFetching}
          title={t("plans:total_amount_financed")}
          difference={data?.generated?.difference}
          data={data?.generated?.data}
          text={
            <>
              {data?.generated?.amount.toLocaleString()}
              <sup className="fs-5"> KD</sup>
            </>
          }
        />
      </Col>
      <Col lg={4} className="mb-3">
        <MediumLineCard
          loading={isFetching}
          title={t("plans:accepted_plans_amount")}
          difference={data?.accepted?.difference}
          data={data?.accepted?.data}
          text={
            <>
              {data?.accepted?.amount.toLocaleString()}
            </>
          }
        />
      </Col>
      <Col lg={4} className="mb-3">
        <MediumPieCard
          loading={isFetching}
          title={t("plans:total_plans")}
          data={data?.totals}
        />
      </Col>
    </>
  );
};

export default FIStatsWidget;
