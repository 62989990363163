import { useQuery } from '@tanstack/react-query';
import Amount from 'components/widgets/Amount';
import TransactionStatusRenderer from 'components/widgets/TransactionStatusRenderer';
import React, { useState } from 'react'
import { Button, Card, Col, ProgressBar, Row, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import dashboard from 'utils/api/dashboard';
import { transactions } from 'utils/api/transactions';
import { transactionDoneStatuses } from 'utils/constants';
import TransactionStatus from 'utils/enums/TransactionStatus';
import TransactionType from 'utils/enums/TransactionType';
import { dateAndTime, download } from 'utils/functions/utilities';
import { usePaginate } from 'utils/hooks/paginate';

type Props = {}

const ClientsPaymentHistory = (props: Props) => {
    const { t } = useTranslation(["common", "plans", "schools"]);
    const { id } = useParams<{ id: string }>();
    const [filter, setFilter] = useState({
        status: transactionDoneStatuses,
        type: [TransactionType.COLLECTION, TransactionType.RECHARGE],
        reserve: id ?? undefined
    });
    const { data } = usePaginate({
        key: "clients-payment-history",
        api: transactions.list,
        filter
    })

    const { data: progress } = useQuery(["schoolPaymentHistoryProgress"], () => dashboard.schoolPaymentHistoryProgress(id!),
        {
            enabled: !!id,
            placeholderData: { paid: 1, unpaid: 1 }
        });

    async function getInvoice(id: number) {
        const data = await transactions.generatedDocument(id, "invoice");
        download(`Rubapay-${id}-invoice.pdf`, data);
    }

    return (
        <Row className='gy-4'>
            <Col lg={12}>
                <Row className='text-dark mb-4'>
                    <Col col={6} className="mb-2 mb-sm-3">
                        <p className="fbs-4 mb-1">{t("common:total_paid")}</p>
                        <p className="fs-4 fw-semibold">
                            {progress?.paid?.toLocaleString() || 0}
                            <sup className="mx-1">{t("kd")}</sup>
                        </p>
                    </Col>
                    <Col col={6} className="mb-2 mb-sm-3 text-end">
                        <p className="fbs-4 mb-1">{t("plans:total_amount")}</p>
                        <p className="fs-4 fw-semibold">
                            {(progress?.paid + progress?.unpaid).toLocaleString()}{" "}
                            <sup className="">{t("kd")}</sup>
                        </p>
                    </Col>
                    <Col lg={12}>
                        <ProgressBar
                            now={progress?.paid / (progress?.paid + progress?.unpaid) * 100}
                            label={`${(progress?.paid / (progress?.paid + progress?.unpaid) * 100).toFixed(0)}%`}
                            variant="success"
                        />
                    </Col>
                </Row>
            </Col>

            <Col lg={12}>
                <Card className="main-card mb-3" >
                    <Card.Header>
                        <h6 className="text-dark fs-6 fw-bold m-0">{t("payment_history")}</h6>
                    </Card.Header>
                    <Card.Body>

                        <Table className="mb-0">
                            <thead>
                                <tr>
                                    <th>{t("plans:plan_id")}</th>
                                    <th>{t("due_date")}</th>
                                    <th>{t("scheduled_date")}</th>
                                    <th>{t("plans:amount")}</th>
                                    <th>{t("plans:applicant_name")}</th>
                                    <th>{t("plans:installment")}</th>
                                    <th>{t("plans:status")}</th>
                                    <th>{t("plans:actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data?.data?.map((trx: any) => (
                                        <tr key={trx.id}>
                                            <td>
                                                <Link to={`/plans/${trx.plan.id}`}>
                                                    {trx.plan.public_id}
                                                </Link>
                                            </td>
                                            <td>{dateAndTime(trx.due_date, true)}</td>
                                            <td>{dateAndTime(trx.scheduled_date, true)}</td>
                                            <td><Amount amount={trx.amount} /></td>
                                            <td>{trx.user.name}</td>
                                            <td className='fw-bold'>{t('x_out_of_y', { x: trx.order, y: trx.total_per_plan })}</td>
                                            <td><TransactionStatusRenderer {...trx} /></td>
                                            <td>
                                                {trx.status != TransactionStatus.MANUALLY_SETTLED &&
                                                    <Button
                                                        className="p-0 text-decoration-none fs-7 fw-semibold text-dark text-opacity-75"
                                                        onClick={() => getInvoice(trx.id)}
                                                        variant="link"
                                                    >
                                                        <i className="bi bi-arrow-down-circle mx-1"></i>
                                                        {t("invoice")}
                                                    </Button>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card >
            </Col>
        </Row >
    )
}

export default ClientsPaymentHistory