import { useQuery } from '@tanstack/react-query';
import Amount from 'components/widgets/Amount';
import { Card, Col, Modal, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { reserveTransactions } from 'utils/api/reserveTransactions';
import { dateAndTime } from 'utils/functions/utilities';
import { useEffect, useState } from 'react';
import TransactionStatusRenderer from 'components/widgets/TransactionStatusRenderer';

type Props = {}

const ClientsTransfers = (props: Props) => {
    const { t } = useTranslation(["common", "plans", "schools"]);
    const { id } = useParams<{ id: string }>();
    const [selectedTransaction, setSelectedTransaction] = useState<any>(null);
    const [showModal, setShowModal] = useState(false);

    const { data } = useQuery(["reserve-transactions-transfers", {
        id: id
    }], () => reserveTransactions.transferByReserveId(id!), {
        enabled: !!id,
        placeholderData: []
    });

    const { data: transactions } = useQuery(["reserve-transactions-transfers-transactions", {
        scheduledDate: selectedTransaction?.scheduled_date,
    }], () => reserveTransactions.transactionsByScheduledDate(selectedTransaction?.scheduled_date!), {
        enabled: !!selectedTransaction?.scheduled_date,
        placeholderData: []
    });

    const handleRowClick = (trx: any) => {
        setSelectedTransaction(trx);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedTransaction(null);
    };

    return (
        <Row className='gy-4'>
            <Col lg={12}>
                <Card className="main-card mb-3">
                    <Card.Header>
                        <h6 className="text-dark fs-6 fw-bold m-0">{t("transfers")}</h6>
                    </Card.Header>
                    <Card.Body>
                        <Table className="mb-0">
                            <thead>
                                <tr>
                                    <th>{t("scheduled_date")}</th>
                                    <th>{t("plans:amount")}</th>
                                    <th>{t("settled")}</th>
                                    <th>{t("plans:status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((trx: any) => (
                                    <tr
                                        key={trx.id}
                                        onClick={() => handleRowClick(trx)}
                                        className="cursor-pointer hover:bg-gray-50"
                                    >
                                        <td>{dateAndTime(trx.scheduled_date, true)}</td>
                                        <td><Amount amount={trx.amount} /></td>
                                        <td><Amount amount={trx.amount_after_discount} /></td>
                                        <td>
                                            {trx.amount === trx.paid ?
                                                <div className="badge bg-success">{t('settled')}</div> :
                                                trx.paid == 0 ?
                                                    <div className="badge bg-gray">{t('not_settled')}</div> :
                                                    <div className="badge bg-warning">{t('partially_settled')}</div>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>

            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="font-semibold w-100 d-flex justify-content-center">
                        {dateAndTime(selectedTransaction?.scheduled_date, true)} - <Amount amount={selectedTransaction?.amount} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedTransaction && (
                        <div>
                            <Table className="w-full">
                                <thead>
                                    <tr className="border-b">
                                        <th className="py-3">{t('plans:plan')}</th>
                                        <th className="py-3">{t('plans:name')}</th>
                                        <th className="py-3 text-right">{t('plans:amount')}</th>
                                        <th className="py-3 text-center">{t('plans:status')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactions?.map((transaction: any, index: number) => (
                                        <tr key={index} className="border-b last:border-0">
                                            <td className="py-2.5">
                                                <Link to={`/plans/${transaction.plan}`}>
                                                    {transaction.public_id}
                                                </Link>
                                            </td>
                                            <td className="py-2.5">{transaction.user}</td>
                                            <td className="py-2.5 text-right"><Amount amount={transaction.amount} /></td>
                                            <td className="py-2.5 text-center">
                                                <TransactionStatusRenderer status={transaction.status} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </Row>
    );
};

export default ClientsTransfers;