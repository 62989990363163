import React, { useRef, useEffect, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useField } from "formik";
import { Button } from "reactstrap";
import files from "utils/api/files";
import { imageUrl, imageURLToDataURL } from "utils/functions/utilities";
import { useTranslation } from "react-i18next";

interface SignatureComponentProps {
  name: string;
  initialSignatureId?: string;
  disabled?: boolean;
  hideLabel?: boolean;
}

const SignatureComponent: React.FC<SignatureComponentProps> = ({
  name,
  initialSignatureId,
  disabled = false,
  hideLabel = false,
}) => {
  const ref = useRef<SignatureCanvas>(null!);
  const [, meta, helpers] = useField(name);
  const [fetched, setFetched] = useState<boolean>(false);
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    (async () => {
      if (ref.current && initialSignatureId && !fetched) {
        setFetched(true);
        const url = await files.view(initialSignatureId);
        const data = await imageURLToDataURL(imageUrl(url));
        ref.current.fromDataURL(data);
      }
    })();
  }, [initialSignatureId, ref.current]);

  useEffect(() => {
    if (disabled) {
      ref.current.off();
    } else {
      ref.current.on();
    }
  }, [disabled]);

  async function clear() {
    ref.current.clear();
    const emptyBlob = new Blob();
    helpers.setValue(emptyBlob);
    helpers.setTouched(true);
  }

  async function updated() {
    const dataUrl = ref.current.toDataURL();
    const blob = await (await fetch(dataUrl)).blob();
    helpers.setValue(blob);
    helpers.setTouched(true);
  }

  return (
    <>
      <div>
        {!hideLabel && <h4>{t('sign_here')}</h4>}
        <SignatureCanvas
          ref={ref}
          penColor="#0033CC"
          canvasProps={{
            style: {
              height: 200,
              width: "100%",
              maxWidth: 400,
              border: "1px solid #dee2e6",
            },
            className: "sigCanvas max-w-[400px]",
          }}
          onEnd={updated}
        />
      </div>
      {!disabled && (
        <Button
          className="w-100 mt-2"
          size="lg"
          color="secondary"
          outline
          type="button"
          style={{ maxWidth: 400 }}
          onClick={clear}
        >
          {t("clear")}
        </Button>
      )}
    </>
  );
};

export default SignatureComponent;
