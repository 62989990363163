import { useQuery } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import Filter from "components/inputs/Filter";
import PaginatedTable from "components/widgets/PaginatedTable";
import { useMemo, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import plans from "utils/api/plans";
import Role from "utils/enums/Role";
import { isRole } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";
import { usePaginate } from "utils/hooks/paginate";
import useWindowDimensions from "utils/hooks/window";
import plansTablecolumns, { plansTablecolumnVisibility } from "../tableConfig";
import TransactionsSinglePayment from "./components/SinglePayment";

const PlanTransactions = (props: any) => {
  const [filter, setFilter] = useState("");
  const { data: user } = useUser();
  const { data: pending } = useQuery(
    ["transactions", { type: "pending" }],
    plans.pendingTransactions,
    {
      placeholderData: [],
      enabled: isRole(Role.FI, user),
    }
  );
  const { t } = useTranslation(["plans", "common"]);
  const { width } = useWindowDimensions();

  const paginate = usePaginate({
    key: "transactions",
    api: plans.transactions,
    filter: filter,
  });

  const table = useReactTable({
    data: paginate.data?.data || [],
    columns: useMemo(() => plansTablecolumns(t), [t]),
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: useMemo(
        () => plansTablecolumnVisibility(width, user?.role_id!),
        [width]
      ),
    },
  });

  return (
    <Row>
      <Col lg={12} className="mb-4">
        <h4 className="text-dark fw-semibold">{t("transactions")}</h4>
        <p className="text-gray">{t("control_money")}</p>
      </Col>
      <Col lg="12">
        {isRole(Role.FI, user) && (
          <Card className="mb-3">
            <Card.Header>
              <Row className="justify-content-between align-items-center">
                <Col lg="3">
                  <h5>{t("pending_transactions")}</h5>
                </Col>
                {/* <Col lg="3" className="text-end">
                <TransactionsBulkPayment />
              </Col> */}
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg="12">
                  <Table className="mb-0">
                    <thead>
                      <tr>
                        <th>PlanID</th>
                        <th>Applicant name</th>
                        <th>Amount</th>
                        <th>Payment Plan</th>
                        <th>School Trx</th>
                        <th>Ruba Trx</th>
                        {/* <th>{t("plans:actions")}</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {pending?.map((item: any) => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.user.name}</td>
                          <td>
                            {item.principal_amount} {t("common:kd")}
                          </td>
                          <td>
                            {item.number_of_installments} {t("plans:payments")}
                          </td>
                          <td>
                            <TransactionsSinglePayment plan={item} />
                          </td>
                          <td>
                            <TransactionsSinglePayment ruba plan={item} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
        <Card className="mt-5">
          <Card.Header>
            <Row className="justify-content-between align-items-center">
              <Col lg="3">
                <h5>{t("completed_plans")}</h5>
              </Col>
              <Col lg="3" className="text-end">
                <Filter filter={filter} setFilter={setFilter} />
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row>
              <PaginatedTable paginate={paginate} table={table} />
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default PlanTransactions;
