import { VisibilityState, createColumnHelper } from "@tanstack/react-table";
import Amount from "components/widgets/Amount";
import TransactionStatusRenderer from "components/widgets/TransactionStatusRenderer";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import Role from "utils/enums/Role";
import { arabicValue, paymentIsDone } from "utils/functions/utilities";

const columnHelper = createColumnHelper<any>();
const clientsTablecolumns = (t: any) => [
  columnHelper.accessor("transactions", {
    id: "id",
    header: t("plans:id"),
    cell: (info) => <Link
      className="text-decoration-none"
      to={`/plans/${info.getValue()[0]?.plan?.id}`}
    >
      {info.getValue()[0]?.plan?.public_id}
    </Link>
  }),
  columnHelper.accessor("name", {
    id: "name",
    header: t("name"),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("students", {
    id: "students",
    header: t("plans:students"),
    cell: (info) => info.row.original?.students?.map((i: any) => i.name).join(', '),
  }),
  // branch
  columnHelper.accessor("students", {
    id: "branch",
    header: t("branch"),
    cell: (info) => arabicValue(info.getValue()[0]?.branch, "name") || "-",
  }),
  columnHelper.accessor("mobile", {
    id: "mobile",
    header: t("auth:mobile"),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("transactions", {
    id: "status",
    header: t("plans:status"),
    cell: (info) => {
      let pendingAndOverdue = info.getValue().find((t: any) => !paymentIsDone(t) && DateTime.fromISO(t.due_date).diffNow().milliseconds < 0);
      if (!pendingAndOverdue) pendingAndOverdue = info.getValue().find((t: any) => !paymentIsDone(t));
      if (!pendingAndOverdue) pendingAndOverdue = info.getValue()[0];
      return <TransactionStatusRenderer {...pendingAndOverdue} />
    },
  }),
  columnHelper.accessor("transactions", {
    id: "total",
    header: t("plans:amount"),
    cell: (info) => {
      const amount = info.getValue().reduce((acc: number, t: any) => acc + t.amount, 0);
      return <Amount amount={amount} />
    },
  }),
  // paid amount
  columnHelper.accessor("transactions", {
    id: "paid_amount",
    header: t("common:paid"),
    cell: (info) => {
      const amount = info.getValue().reduce((acc: number, t: any) => {
        if (paymentIsDone(t)) acc += t.amount;
        return acc;
      }, 0);
      return <Amount amount={amount} />
    },
  }),
  columnHelper.accessor("transactions", {
    id: "progress",
    header: t("plans:installments"),
    cell: (info) => {
      const total = info.getValue().length;
      const paid = info.getValue().filter((t: any) => paymentIsDone(t)).length;
      return t("common:x_out_of_y", { x: paid, y: total })
    },
  }),
];

export function columnOrder(role: Role) {
  const orders: any = {
    [Role.ADMIN]: [
      "name",
    ],
    [Role.MODERATOR]: [
      "name",
    ],
    [Role.FI]: [
      "id",
      "name",
    ],
    [Role.SCHOOL]: [
      "id",
      "name",
    ],
  };
  return orders[role];
}

export const clientsTablecolumnVisibility = (
  width: number,
  role: Role
): VisibilityState => {
  let hiddenColumns: any = {};
  if (role === Role.FI) hiddenColumns = { ...hiddenColumns, students: false };
  return hiddenColumns;
};

export default clientsTablecolumns;
