// AdminBranchesByType

import { useQuery } from '@tanstack/react-query';
import MediumPieCard from 'components/widgets/Dashboard/MediumPieCard';
import { capitalize } from 'lodash';
import { Card, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import dashboard from 'utils/api/dashboard';
import { graphColors } from 'utils/constants';

type Props = {}

const AdminBranchesByType = (props: Props) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryParamsObject = Object.fromEntries(queryParams.entries());
    const { t } = useTranslation(["common","plans"]);

    const { data, isFetching } = useQuery(
        ["dashboard", "admin-branches-by-type", queryParamsObject],
        () => dashboard.adminBranchesByType(queryParamsObject),
        {
            placeholderData: {
                nonPartners: [],
                partners: [],
                total: 0
            },
        }
    );

    return (
        <>
            <Col lg={6} className="mb-3" >
                <MediumPieCard
                    loading={isFetching}
                    title={`${t("common:partners_branches")} (${t("total")}: ${data?.partners?.reduce((sum: number, item: any) => sum + +item.value, 0) || 0})`}
                    data={data?.partners?.map((item: any, index: number) => ({
                        name: t("plans:"+("a_"+item.name).toLowerCase()),
                        value: +item.value,
                        color: graphColors[index]
                    }))}
                />
            </Col>
            <Col lg={6} className="mb-3" >
                <MediumPieCard
                    loading={isFetching}
                    title={`${t("common:non_partners_branches")} (${t("total")}: ${data?.nonPartners?.reduce((sum: number, item: any) => sum + +item.value, 0) || 0})`}
                    data={data?.nonPartners?.map((item: any, index: number) => ({
                        name: t("plans:"+("a_"+item.name).toLowerCase()),
                        value: +item.value,
                        color: graphColors[index]
                    }))}
                />
            </Col>
        </>
    );
};

export default AdminBranchesByType;