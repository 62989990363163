import TransactionStatusRenderer from "components/widgets/TransactionStatusRenderer";
import TransactionTypeRenderer from "components/widgets/TransactionTypeRenderer";
import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import { DateTime } from "luxon";
import { useState } from "react";
import { Button, Card, Col, ProgressBar, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { transactions } from "utils/api/transactions";
import TransactionStatus from "utils/enums/TransactionStatus";
import { download, installmentColor, paymentIsDone } from "utils/functions/utilities";
import useAlert from "utils/hooks/alert";
import { usePaginate } from "utils/hooks/paginate";

type Props = {
  reserve?: any;
};


const InstallmentsHistory = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const [filter, setFilter] = useState(id ? {
    reserve: id,
  } : {});
  const [progress, setProgress] = useState({
    successful: [],
    total_paid: 0,
    total_amount: 0,
    progress: 0,
  });
  const { confirmOrDeny } = useAlert();
  const { t } = useTranslation(["common", "plans", "schools"]);

  const { data } = usePaginate({
    key: ["history_transaction", {
      id
    }],
    api: transactions.list,
    filter,
    options: {
      placeholderData: { meta: {}, data: [] },
      onSuccess: ({ data }: any) => {
        const successful = data?.filter((item: any) => paymentIsDone(item));
        const total_paid = successful?.reduce(
          (acc: number, item: any) => acc + item.amount,
          0
        );
        const total_amount = data?.reduce(
          (acc: number, item: any) => acc + item.amount,
          0
        );
        const progress = (total_paid / total_amount) * 100;
        setProgress({ successful, total_paid, total_amount, progress });
      },
    },
  });

  async function getInvoice(id: number) {
    const data = await transactions.generatedDocument(id, "invoice");
    download(`Rubapay-${id}-invoice.pdf`, data);
  }

  function refund(id: number) {
    confirmOrDeny({
      title: t("initiate_refund"),
      text: t("initiate_refund_desc"),
      confirmButtonText: t("plans:approve"),
      showDenyButton: false,
      onConfirm: async () => {
        console.log("sending refund request")
        // apply refund
        const res = await transactions.approveRefund(id);
        console.log(res);

      },
    });
  }



  return (
    <>
      <Col lg={12} className="mb-5">
        <Row className="text-dark">
          <Col lg={6} className="mb-2 mb-sm-3">
            <p className="fbs-4 mb-1">{t("common:total_paid")}</p>
            <p className="fs-4 fw-semibold">
              {progress?.total_paid?.toLocaleString() || 0}
              <sup className="mx-1">{t("kd")}</sup>
            </p>
          </Col>
          <Col lg={6} className="mb-2 mb-sm-3 text-end">
            <p className="fbs-4 mb-1">{t("plans:total_amount")}</p>
            <p className="fs-4 fw-semibold">
              {progress?.total_amount?.toLocaleString()}{" "}
              <sup className="">{t("kd")}</sup>
            </p>
          </Col>
          <Col lg={12}>
            <ProgressBar
              now={progress?.progress}
              label={`${progress?.progress?.toFixed(0)}%`}
              variant="success"
            />
          </Col>
        </Row>
      </Col>
      <Col lg={12}>
        <Card className="main-card mb-3">
          <Card.Body>
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>{t("plans:plan_id")}</th>
                  <th>{t("due_date")}</th>
                  <th>{t("transaction_id")}</th>
                  <th>{t("plans:amount")}</th>
                  <th>{t("payment_date")}</th>
                  <th>{t("plans:installments")}</th>
                  <th>{t("plans:status")}</th>
                  <RoleBasedRenderer
                    admin={
                      <>
                        <th>{t("common:type")}</th>
                        <th>{t("plans:actions")}</th>
                      </>
                    }
                    fi={
                      <th>{t("plans:actions")}</th>
                    }
                    user={
                      <th>{t("plans:actions")}</th>
                    } />
                </tr>
              </thead>
              <tbody>
                {data?.data?.filter((item: any) => paymentIsDone(item)).map((item: any) => (
                  <tr key={item.id}>
                    <td>
                      <Link
                        className="text-decoration-none"
                        to={`/plans/${item?.plan?.id}`}
                      >
                        {item?.plan?.public_id}
                      </Link>
                    </td>
                    <td>
                      {DateTime.fromISO(item.due_date).toFormat(
                        "yyyy/MM/dd"
                      )}
                    </td>
                    <td>
                      {item?.transactionId}
                    </td>
                    <td className={"fw-bold " + installmentColor(item)}>
                      {item.amount} {t("kd")}
                    </td>
                    <td className="fw-bold">{DateTime.fromISO(item.payment_date).toFormat(
                      "yyyy/MM/dd"
                    )}</td>
                    <td className="fw-bold">
                      {t("x_out_of_y", {
                        x: item.order,
                        y: item.total_per_plan,
                      })}
                    </td>
                    <td className="fw-bold">
                      <TransactionStatusRenderer
                        {...item}
                      />
                    </td>
                    <RoleBasedRenderer admin={
                      <>
                        <td>
                          <TransactionTypeRenderer type={item.type} />
                        </td>
                        <td>
                          {item.status == TransactionStatus.PENDING_REFUND &&
                            <Button
                              className="p-0 text-decoration-none fs-7 fw-semibold text-dark text-opacity-75"
                              onClick={() => refund(item.id)}
                              variant="link"
                            >
                              <i className="bi bi-repeat mx-1"></i>
                              {t("refund_action")}
                            </Button>
                          }
                        </td>
                      </>
                    } fi={
                      <td>
                        {item.status != TransactionStatus.MANUALLY_SETTLED &&
                          <Button
                            className="p-0 text-decoration-none fs-7 fw-semibold text-dark text-opacity-75"
                            onClick={() => getInvoice(item.id)}
                            variant="link"
                          >
                            <i className="bi bi-arrow-down-circle mx-1"></i>
                            {t("invoice")}
                          </Button>
                        }
                      </td>
                    } user={
                      <td>
                        {item.status != TransactionStatus.MANUALLY_SETTLED &&
                          <Button
                            className="p-0 text-decoration-none fs-7 fw-semibold text-dark text-opacity-75"
                            onClick={() => getInvoice(item.id)}
                            variant="link"
                          >
                            <i className="bi bi-arrow-down-circle mx-1"></i>
                            {t("invoice")}
                          </Button>
                        }
                      </td>
                    } />
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default InstallmentsHistory;
