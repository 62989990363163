import { useQuery } from '@tanstack/react-query';
import Checkbox from "components/inputs/Checkbox";
import Input from 'components/inputs/Input';
import { Formik } from 'formik';
import { useState } from 'react';
import { Accordion, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import students from 'utils/api/students';
import { statusSpaceToUnderScore } from "utils/functions/utilities";

type Props = {
    filter?: any;
    setFilter?: (filter: any) => void;
};

const StudentsFilter = ({ filter = {}, setFilter = () => { } }: Props) => {
    const [show, setShow] = useState(false);
    const { t } = useTranslation(["common", "plans","schools"]);
    const { data: filterables } = useQuery(["filterables"], students.filterables, {
        placeholderData: {
            status: [],
            branches: [],
            grades: [],
            schoolYears: [],
        },
    });

    const handleSubmit = (values: any) => {
        const newFilters: any = {};
        
        Object.entries(values.grades || {}).forEach(([id, checked]) => {
            if (checked) {
                newFilters.grades = [...(newFilters.grades || []), parseInt(id)];
            }
        });

        Object.entries(values.status || {}).forEach(([status, checked]) => {
            if (checked) {
                newFilters.status = [...(newFilters.status || []), status];
            }
        });

        if (values.student_id) {
            newFilters.student_id = values.student_id;
        }

        if (values.schoolYear) {
            newFilters.schoolYear = values.schoolYear;
        }

        setFilter(newFilters);
        setShow(false);
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                search: filter.search,
                status: filterables?.statuses?.reduce?.(
                    (acc: any, status: string) => ({
                        ...acc,
                        [status]: false,
                    }),
                    {}
                ),
                grades: filterables?.grades?.reduce?.(
                    (acc: any, grade: any) => ({
                        ...acc,
                        [grade.id]: false,
                    }),
                    {}
                ),
                student_id: filter.student_id,
                schoolYear: filter.schoolYear,
            }}
            onSubmit={handleSubmit}
        >
            {(formikBag) => (
                <Form>
                    <Row className="d-flex justify-content-end flex-wrap">
                        <Col lg="6" className="position-relative mb-3 mb-lg-0">
                            <i
                                style={{ zIndex: 1 }}
                                className="position-absolute mt-2 bi bi-search fs-5 text-black-50 ms-3 py-2"
                            ></i>
                            <Input
                                className="ps-5 mt-lg-0"
                                name="search"
                                placeholder={t("common:search")}
                            />
                        </Col>
                        <Col className="mb-3 mb-sm-0">
                            <Button
                                type="button"
                                className="w-100 h-100 p-3"
                                variant="outline-light"
                                onClick={() => setShow(true)}
                            >
                                <i className="bi bi-filter me-2"></i>
                                {t("common:filters")}
                            </Button>
                        </Col>
                        <Col className="mb-3 mb-sm-0">
                            <Button
                                type="button"
                                className="w-100 h-100 p-3 px-4"
                                variant="primary"
                                onClick={formikBag.submitForm}
                            >
                                {t("common:apply")}
                            </Button>
                        </Col>
                        <Modal
                            centered
                            aria-labelledby="contained-modal-title-vcenter"
                            show={show}
                            onHide={() => setShow(false)}
                            size="sm"
                        >
                            <Modal.Header
                                className="d-flex justify-content-between"
                                closeButton
                            >
                                <Modal.Title className="fs-6">
                                    {t("common:filters")}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Accordion className="mb-4">
                                    {filterables?.grades?.length > 0 && (
                                        <Accordion.Item eventKey="0" className="p-2 border-0">
                                            <Accordion.Header>{t("schools:grades")}</Accordion.Header>
                                            <Accordion.Body>
                                                {filterables?.grades?.map((grade: any) => (
                                                    <Checkbox
                                                        key={grade.id}
                                                        name={`grades[${grade.id}]`}
                                                        label={grade.name}
                                                    />
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )}
                                    {filterables?.statuses?.length > 0 && (
                                        <Accordion.Item eventKey="1" className="p-2 border-0">
                                            <Accordion.Header>{t("plans:status")}</Accordion.Header>
                                            <Accordion.Body>
                                                {filterables?.statuses?.map((status: string) => (
                                                    <Checkbox
                                                        key={status}
                                                        name={`status[${status}]`}
                                                        label={t(statusSpaceToUnderScore(status))}
                                                    />
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )}
                                    {filterables?.schoolYears?.length > 0 && (
                                        <Accordion.Item eventKey="2" className="p-2 border-0">
                                            <Accordion.Header>{t("common:school_year")}</Accordion.Header>
                                            <Accordion.Body>
                                                <Form.Select 
                                                    name="schoolYear"
                                                    onChange={(e) => formikBag.setFieldValue('schoolYear', e.target.value)}
                                                    value={formikBag.values.schoolYear || ''}
                                                >
                                                    <option value="">{t("common:select_school_year")}</option>
                                                    {filterables?.schoolYears?.map((year: string) => (
                                                        <option key={year} value={year}>
                                                            {year}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )}
                                    <Accordion.Item eventKey="3" className="p-2 border-0">
                                        <Accordion.Header>{t("plans:student_id")}</Accordion.Header>
                                        <Accordion.Body>
                                            <Input name="student_id" />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Modal.Body>
                        </Modal>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default StudentsFilter;